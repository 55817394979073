<template>
  <div class="grid admintext-demo">
    <Toolbar class="toolbar_top">
      <template v-slot:start>
        <button class="p-link" id="btn_back" @click="backTotexte">
          <i class="pi pi-angle-left"></i>
        </button>
        <div class="my-2">
          <h3>{{ t('textManagemet') }}</h3>
        </div>
      </template>
    </Toolbar>
    <div class="col-12 ">
      <div class="card">
        <Toast/>

        <div class="my-2" v-if="countStatusImport>0">
          <h6>{{ t('importSectionCompliance') }}</h6>
        </div>
        <DataTable :value="sectionsText" v-model:expandedRows="expandedRowsSec" dataKey="id"
                   responsiveLayout="scroll"
                   :lazy="true" :totalRecords="totalItemsSection"
                   @page="onPageSection(idDocument, $event.page+1)"
                   :paginator="true"
                   :rows="30" :loading="loadingSection"
                   @rowExpand="onRowExpand($event.data.id, 1)" class="update_section" :reorderableColumns="true"
                   @rowReorder="onRowReorder" :row-class="rowClass">
          <template #empty>
            {{ t('NoDataToDisplay') }}
          </template>
          <Toolbar class="block_add_btn">
            <template v-slot:start>
              <div class="my-2">
                <Button :label="t('addASection')" icon="pi pi-plus"
                        class="add-text p-button-success mr-2"
                        @click="modalSection()"/>

                <Button label="Import" icon="pi pi-upload" class="add-text mr-2" @click="importSections"
                />
              </div>
            </template>
          </Toolbar>
          <template #header>
            <div class="header_title_doc flex justify-content-between align-items-center"
                 v-if="dataDocument">
              <div class="title-doc">
                <img :src="'demo/images/flags/flag-black.png'" :alt="dataDocument.image" width="20"
                     v-if="dataDocument?.live_status?.color==='black'"/>
                <img :src="'demo/images/flags/flag-red.png'" :alt="dataDocument.image" width="20"
                     v-if="dataDocument?.live_status?.color==='red'"/>
                <img :src="'demo/images/flags/flag-yellow.png'" :alt="dataDocument.image" width="20"
                     v-if="dataDocument?.live_status?.color==='yellow'"/>
                <img :src="'demo/images/flags/flag-green.png'" :alt="dataDocument.image" width="20"
                     v-if="dataDocument?.live_status?.color==='green'"/>
                <div class="content_header">
                  <p class="title_update_text">{{ truncate(dataDocument.title) }}</p>
                  <p v-html="truncate(replaceAllColorsWithWhite(dataDocument.description))"></p>
                </div>
              </div>
              <Button icon="pi pi-cog" class="button-table mr-2" @click="modalEditText()"
                      v-tooltip.top="{value:t('edit'), class: 'editBtn'}"/>
            </div>
          </template>
          <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" class="rowReorder"
                  style="display: none"/>
          <Column :expander="true" headerStyle="width: 3rem"/>

          <Column header="" class="td_flag">
            <template #body="slotProps">

              <img :src="'demo/images/flags/flag-yellow.png'" :alt="slotProps.data.image" width="20"
                   v-if="differenceDate(slotProps.data.last_update_compliance, dataDocument?.live_status?.color)"/>
              <img :src="'demo/images/flags/flag-black.png'" :alt="dataDocument.image" width="20"
                   v-if="dataDocument?.live_status?.color==='black'"/>
            </template>
          </Column>
          <Column :header="t('title') " field="title" class="title_section">
            <template #body="slotProps">
              <p>{{ slotProps.data.title }}</p>
            </template>
          </Column>
          <template #expansion="slotProps">
            <div class="p-3 pt-0">
              <DataTable :value="slotProps.data.compliances" responsiveLayout="scroll"
                         class="section_requirement"

                         :lazy="true" :totalRecords="totalItemsCompliance"
                         @page="onpageCompliance(slotProps.data.id, $event.page+1)"
                         :loading="loadingCompliance[slotProps.data.id]" :paginator="true" :rows="30"
              >

                <template #empty>
                  {{ t('NoDataToDisplay') }}
                </template>
                <Column header="" class="td_flag">
                  <template #body="slotProps">
                    <img :src="'demo/images/flags/flag-black.png'" :alt="slotProps.data.image"
                         width="20"
                         v-if="slotProps?.data?.live_status?.color==='black' || dataDocument?.live_status?.color==='black'"/>
                    <img :src="'demo/images/flags/flag-red.png'" :alt="slotProps.data.image"
                         width="20"
                         v-if="slotProps?.data?.live_status?.color==='red' && dataDocument?.live_status?.color!=='black'"/>
                    <img :src="'demo/images/flags/flag-yellow.png'" :alt="slotProps.data.image"
                         width="20"
                         v-if="slotProps?.data?.live_status?.color==='yellow' && dataDocument?.live_status?.color!=='black'"/>
                    <img :src="'demo/images/flags/flag-green.png'" :alt="slotProps.data.image"
                         width="20"
                         v-if="slotProps?.data?.live_status?.color==='green' && dataDocument?.live_status?.color!=='black'"/>
                  </template>
                </Column>
                <Column :header="t('titleOfCompliance')">
                  <template #body="slotProps">
                    <span v-html="slotProps.data.title "> </span>
                  </template>
                </Column>
                <Column style="min-width: 15rem;text-align: right;">
                  <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="btn_edit button-table mr-2"
                            @click="modalCompliance(slotProps.data,'edit')"
                            v-tooltip.top="{value:t('edit'), class: 'editBtn'}"/>
                    <Button icon="pi pi-fw pi-times" class="btn_remove button-table mr-2"
                            @click="deleteCompliance(slotProps.data, slotProps.data.id_section)"
                            v-tooltip.top="{value:t('delete'), class: 'editBtn'}"/>
                  </template>
                </Column>
              </DataTable>
            </div>
          </template>
          <Column style="min-width: 15rem;text-align: right;">
            <template #body="slotProps">
              <Button icon="pi pi-plus" class="btn_add button-table mr-2"
                      @click="modalCompliance(slotProps.data, 'add')"
                      v-tooltip.top="{value:t('add'), class: 'editBtn'}"/>
              <Button icon="pi pi-pencil" class="btn_edit button-table mr-2"
                      @click="modalSection(slotProps.data)"
                      v-tooltip.top="{value:t('edit'), class: 'editBtn'}"/>
              <Button icon="pi pi-fw pi-times" class="btn_remove button-table mr-2"
                      @click="deleteSection(slotProps.data)"
                      v-tooltip.top="{value:t('delete'), class: 'editBtn'}"/>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <Dialog v-model:visible="editTextDialog" :style="{width: '900px'}" :header="t('editRegulatoryText')"
            :modal="true"
            class="modal_general p-fluid" v-model:activeIndex="activeIndex">
      <TabView class="tabs_texte_admin tabview-custom">
        <TabPanel>
          <template #header>
            <span>Identification</span>
          </template>
          <div class="formgrid grid">
            <div class="field-control col">
              <label for="titre">{{ t('title') }}<span>*</span> :</label>
              <InputText id="name" v-model.trim="text.title" required="true" autofocus
                         :class="{'p-invalid': submittedText && !text.title}"/>
              <small class="p-invalid" v-if="submittedText && !text.title">{{
                  t('titleRequired')
                }}</small>
            </div>
            <div class="field-control col">
              <label for="statut">{{ t('status') }}<span>*</span></label>
              <Dropdown v-model="statusText" :options="statusDocument" optionLabel="name" required="true"
                        autofocus
                        :class="{'p-invalid': submittedText && !statusText}"/>
              <small class="p-invalid" v-if="submittedText && !statusText">{{
                  t('statusRequired')
                }}</small>
            </div>
          </div>
          <div class="formgrid grid">

            <div class="field-control col">
              <label for="statut">{{ t('defaultApplicability') }}</label>
              <Dropdown v-model="valueDefaultApp" :options="valuesDefaultApp" optionLabel="label"/>
            </div>
            <div class="field-control col">
              <label for="Priorite">Type<span>*</span> </label>
              <!--              <InputText type="text" v-model="text.document_type"-->
              <!--                         :class="{'p-invalid': submitted && !text.document_type}"/>-->

              <Dropdown v-model="typeDocument" :options="typesDocument" optionLabel="Type"
                        :class="{'p-invalid': submittedText && !typeDocument}"/>
              <small class="p-invalid" v-if="submittedText && !typeDocument">{{
                  t('documentTypeRequired')
                }}</small>

            </div>
          </div>
          <div class="formgrid grid">

            <div class="field-control col">
              <label for="statut">{{ t('subDomain') }}<span>*</span></label>
              <!--                  <Dropdown v-model="text.sub_domain" :options="valuesSubDomain" optionLabel="name" />-->
              <TreeSelect v-model="valueSubDomain" :options="tabCategories" selectionMode="single"
                          placeholder="Select Item"
                          :class="{'p-invalid': submittedText && !text.sub_domain}"
                          @node-select="selectNode"/>
              <small class="p-invalid" v-if="submittedText && !text.sub_domain">{{
                  t('subDomainRequired')
                }}</small>
              <!--                  <Dropdown v-model="selectedGroupedCity" :options="groupedCities"-->
              <!--                            optionLabel="label" optionGroupLabel="label" optionGroupChildren="items">-->
              <!--                  </Dropdown>-->
            </div>
            <div class="field-control col">
              <label for="country">{{ t('country') }}</label>
              <Dropdown v-model="countryText" :options="valueCountries" optionLabel="name"/>
            </div>
          </div>
          <div class="formgrid grid">
            <div class="field-control col">
              <label for="description">Description :</label>
              <!--              <Textarea id="description" v-model="text.description" required="true" rows="5" cols="20"/>-->

              <Editor v-model="text.description" editorStyle="height: 160px"/>

            </div>

          </div>
          <div class="formgrid grid">
            <div class="field-control col">
              <label for="commentaire">{{ t('comment') }} :</label>
              <Textarea id="commentaire" v-model="text.comment" required="true" rows="5" cols="20"/>
            </div>

            <div class="field-control col">
              <div class="field-radiobutton pt-4">
                <Checkbox id="checkOption1" name="option" :binary="true"
                          v-model="text.is_confidential"/>
                <label for="confidentiel">{{ t('confidential') }}</label>
              </div>
            </div>
          </div>
          <div class="formgrid grid">

            <div class="field-control col">
              <label for="app">{{ t('fieldApplication') }} :</label>
              <Textarea id="app" v-model="text.application_fields" required="true" rows="5" cols="20"/>
            </div>
            <div class="field-control col">
              <label for="app">{{ t('novalliaAnnotation') }} :</label>
              <Textarea id="app" v-model="text.annotation_novallia" required="true" rows="5" cols="20"/>
            </div>
          </div>
          <div class="formgrid grid">


            <div class="field-control col">
              <div class="field-radiobutton pt-4">
                <Checkbox id="checkOption1" name="option" :binary="true"
                          v-model="text.dashboard_ratp"/>
                <label for="dashboard_ratp">Dashboard RATP</label>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <template #header>
            <span>{{ t('keyDates') }}</span>
          </template>
          <div class="formgrid grid">
            <div class="field-control col">
              <label for="titre">{{ t('dateOfText') }} :</label>
              <Calendar :showIcon="true" :showButtonBar="true" v-model="dated_at"
                        dateFormat="dd/mm/yy"></Calendar>
            </div>
            <div class="field-control col">
              <label for="statut">{{ t('dateOfLastModification') }} :</label>
              <Calendar :showIcon="true" :showButtonBar="true" v-model="last_updated_at"
                        dateFormat="dd/mm/yy"></Calendar>
            </div>
          </div>
          <div class="formgrid grid">
            <div class="field-control col">
              <label for="textupdate">{{ t('textModifier') }} :</label>
              <!--              <Textarea id="textupdate" v-model="text.update_reason" required="true" rows="5" cols="20"/>-->

              <Editor v-model="text.update_reason" editorStyle="height: 160px"/>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <template #header>
            <span>{{ t('siteAssignment') }}</span>
          </template>
          <div class="grid pb-2">
            <div class="col-6">
              <h6>{{ t('applicability') }}</h6>
              <Dropdown v-model="valueStatusAffect" :options="valuesStatusAffect" optionLabel="label"/>
            </div>
            <div class="col-6">
              <h6>{{ t('copyOfAssociatedSite') }}</h6>
              <div>
                <AutoComplete v-model="selectedDocument" optionLabel="name" :suggestions="documents"
                              @complete="getSelectedDocument"
                              :placeholder="t('idOfDocument')"/>
                <Button :label="t('loadSites')" icon="pi pi-check" class="p-button-text next_btn"
                        @click="listDocuments" :loading="loadingCopySites"
                        style=" border: 1px solid #3458a7 !important;
                                                    background: #3458a7;
                                                    color: white;
                                                    margin-left: 7px;
                                                    top: 6px;"/>
              </div>

              <h6 v-if="documents.length > 0"
                  style="font-size: 12px;margin-top: 14px;margin-bottom: 8px;">
                {{ t('rememberSaveAffectSite') }}</h6>
            </div>

          </div>


          <PickList ref="pickList" v-model="listValuesSite" dataKey="code" @move-to-target="moveToTarget"
                    @move-to-source="moveToSource" :responsive="true" listStyle="height: 250px"
                    @move-all-to-target="moveAllToTarget"
                    @move-all-to-source="moveAllToSource"
                    class="picklist_site" @dblclick.prevent="disableDoubleClick">
            <template #sourceheader>
              <h5>{{ t('sitesAssociated') }}</h5>
              <div class="grid-box grid">
                <div class="col-7" style="border-right: 1px solid">{{ t('reference') }}</div>
                <div class="col-5">{{ t('applicability') }}</div>

              </div>
              <div class="search-filter grid">
                <div class="col-7" style="    padding-right: 0; width: 57.3333%;padding-bottom: 0;">
                  <input type="text" id="myInputSource" @keyup="filterSites('source')"
                         :placeholder="t('search')"
                         title="Type in a name"
                         class="p-inputtext p-component p-column-filter p-column-filter"
                         style="background-color: white;">
                </div>
                <div class="col-5"
                     style=" padding-left: 0;  border: 0 !important;padding-right: 0; width: 39%;padding-bottom: 0;">
                  <input type="text" id="myInputSApp" @keyup="filterSites('sourceApp')"
                         :placeholder="t('search')"
                         title="Type in a name"
                         class="p-inputtext p-component p-column-filter p-column-filter"
                         style="background-color: white;">
                </div>
              </div>
            </template>
            <template #targetheader>
              <h5>{{ t('SitesNotAssociated') }}</h5>

              <div class="grid-box grid">

                <div class="col-7" style="border-right: 1px solid">{{ t('reference') }}

                </div>
                <div class="col-5">{{ t('wording') }}</div>
              </div>
              <div class="search-filter grid">
                <div class="col-7" style="    padding-right: 0; width: 57.3333%;padding-bottom: 0;">
                  <input type="text" id="myInput" @keyup="filterSites('target')"
                         :placeholder="t('search')"
                         title="Type in a name"
                         class="p-inputtext p-component p-column-filter p-column-filter"
                         style="background-color: white;padding-right: 0 !important;border-radius: 0 !important;">
                </div>
                <div class="col-5"
                     style=" padding-left: 0;  border: 0 !important;padding-right: 0; width: 39%;padding-bottom: 0;">
                  <input type="text" id="myInputLib" @keyup="filterSites('targetLib')"
                         :placeholder="t('search')"
                         title="Type in a name"
                         class="p-inputtext p-component p-column-filter p-column-filter"
                         style="background-color: white;">
                </div>
              </div>


            </template>
            <template #item="slotProps">
              <div class="list_related_site flex justify-content-between" :data-key="slotProps.item.id">
                <div class="col-7"
                     v-tooltip.right="slotProps.item.concatSitesName"
                >
                  {{ truncateSiteName(slotProps.item.concatSitesName, 30) }}
                </div>
                <div class="col-5" v-tooltip.right="slotProps.item.typeLabel">
                  {{ truncateSiteName(slotProps.item.typeLabel, 20) }}
                </div>
                <span style="display: none">
                      {{ slotProps.item.concatSitesName }}
                    </span>
              </div>
            </template>
          </PickList>


        </TabPanel>
        <TabPanel>
          <template #header>
            <span>Documents</span>
          </template>
          <div class="formgrid grid">
            <div class="field-control col-12">
              <label for="uploadfiles">Fiche de synthése :</label>
              <div class="flex btn_download"
                   :style="{ 'pointer-events': summary_file ?  'auto': 'none' , opacity: summary_file ?  1 : 0}">
                <Button :label="summary_file?.name" class="p-button-text event_remove"
                        @click="attachFile(summary_file?.id, summary_file?.name)"/>
                <Button icon="pi pi-times" class="p-button-text event_remove"
                        @click="deleteFile(summary_file?.id, 'summary')"/>
              </div>
              <FileUpload mode="basic" ref="fileSummary" label="Import"
                          chooseLabel="Import" class="mr-2 inline-block"
                          v-on:change="onUploadSummaryFile()"/>
            </div>
            <div class="field-control col-12">
              <label for="uploadfileannex">Fichier Annexes et illustrations :</label>
              <div class="flex btn_download "
                   :style="{ 'pointer-events': illustrations_file ?  'auto': 'none' , opacity: illustrations_file ?  1 : 0}">
                <Button :label="illustrations_file?.name" class="p-button-text event_remove"
                        @click="attachFile(illustrations_file?.id, illustrations_file?.name)"/>
                <Button icon="pi pi-times" class="p-button-text event_remove"
                        @click="deleteFile(illustrations_file?.id, 'illustration')"/>
              </div>

              <FileUpload mode="basic" ref="fileIllustration" label="Import"
                          chooseLabel="Import" class="mr-2 inline-block"
                          v-on:change="onUploadIllustrationFile()"/>
            </div>
            <div class="field-control col-12">
              <label for="titre">URL:</label>
              <InputText id="name" v-model="urlDoc" required="true" autofocus class="mt-2"/>
            </div>
          </div>
        </TabPanel>
      </TabView>
      <template #footer>
        <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove" @click="hideDialog"/>
        <Button :label="t('save')" icon="pi pi-check" class="p-button-text next_btn" @click="editText()"
                :disabled="btnDocumentSubmit"/>
      </template>
    </Dialog>
    <Dialog v-model:visible="sectionDialog" :style="{width: '900px'}" :header="titleModalSection" :modal="true"
            class="modal_general p-fluid">
      <div class="formgrid grid">
        <div class="field col">
          <label for="titre">{{ t('wording') }}* :</label>
          <Textarea id="name" v-model.trim="section.title" autofocus
                    :class="{'p-invalid': submitted && !section.title}"
                    rows="5" cols="20"/>
          <small class="p-invalid" v-if="submitted && !section.title">Title is required.</small>
        </div>
      </div>
      <div class="formgrid grid">
        <div class="field col">
          <label for="titre">{{ t('order') }} :</label>
          <InputNumber id="name" v-model="section.ranked" autofocus
          />
        </div>
      </div>
      <template #footer>
        <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove" @click="hideDialog"/>
        <Button :label="t('save')" v-if="section.id" icon="pi pi-check" class="p-button-text next_btn"
                @click="editSection()" :disabled="btnSectionSubmit"/>
        <Button :label="t('save')" v-else icon="pi pi-check" class="p-button-text next_btn"
                @click="addSection()"
                :disabled="btnSectionSubmit"/>
      </template>
    </Dialog>
    <Dialog v-model:visible="editComplianceDialog" :style="{width: '900px'}" :header="titleModalCompliance"
            :modal="true"
            class="modal_general p-fluid">
      <div class="formgrid grid">
        <div class="field col">
          <label for="titre">{{ t('order') }} :</label>
          <InputNumber id="name" v-model="compliance.ranked" autofocus
                       :class="{'p-invalid': submitted && !compliance.ranked}"/>
          <small class="p-invalid" v-if="submitted && !compliance.ranked">{{ t('orderRequired') }}</small>
        </div>
        <div class="field-control col">
          <label for="Priorite">{{ t('evaluation') }}<span>*</span> </label>
          <Dropdown v-model="evaluationStatus" :options="valuesEvaluations" optionLabel="name" required="true"
          />
        </div>
      </div>
      <div class="formgrid grid">
        <div class="field-control col">
          <label for="titre">{{ t('modificationDate') }} :</label>
          <Calendar :showIcon="true" :showButtonBar="true" v-model="last_update_compliance"
                    dateFormat="dd/mm/yy"></Calendar>
        </div>
        <div class="field-control col">
        </div>
      </div>
      <div class="formgrid grid">
        <div class="field col">
          <label for="titre">{{ t('wording') }}* :</label>
          <Editor v-model="compliance.title" editorStyle="height: 160px"
                  :class="{'p-invalid': submitted && !compliance.title}">
            <template #toolbar>
              <span class="ql-formats">
                        <select class="ql-header"></select>
                        <select class="ql-font"></select>
                        <button class="ql-bold"></button>
                        <button class="ql-italic"></button>
                        <button class="ql-underline"></button>
                        <select class="ql-color"></select>
                        <select class="ql-background"></select>
              </span>
              <span class="ql-formats">
                 <button class="ql-list" value="ordered"></button>
                 <button class="ql-list" value="bullet"></button>
                 <select class="ql-align"></select>
              </span>
              <span class="ql-formats">
                 <button class="ql-link"></button>
                 <button class="ql-image"></button>
                 <button class="ql-code-block"></button>
              </span>
              <span class="ql-formats">
                  <button class="ql-clean"></button>
              </span>
            </template>
          </Editor>
          <small class="p-invalid" v-if="submitted && !compliance.title">{{ t('titleRequired') }}</small>
        </div>
      </div>
      <div class="formgrid grid">
        <div class="field-control col">
          <label for="app">{{ t('complianceAnnotation') }} :</label>
          <Textarea id="app" v-model="compliance.annotation_compliance" required="true" rows="5" cols="20"
          />
        </div>
      </div>
      <div class="formgrid grid">
        <div class="field-control col">
          <label for="app">{{ t('titleOfCompliance') }} :</label>
          <Textarea id="app" v-model="compliance.title_rte" required="true" rows="5" cols="20"
          />
        </div>
      </div>
      <template #footer>
        <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove" @click="hideDialog"/>
        <Button :label="t('save')" v-if="compliance.id" icon="pi pi-check" class="p-button-text next_btn"
                @click="editCompliance()" :disabled="btnComplianceSubmit"/>
        <Button :label="t('save')" v-else icon="pi pi-check" class="p-button-text next_btn"
                @click="addCompliance()"
                :disabled="btnComplianceSubmit"/>
      </template>
    </Dialog>
    <Dialog v-model:visible="importSectionsDialog" :style="{width: '900px'}" :header="t('importSection')"
            :modal="true"
            class="modal_general p-fluid">
      <div class="formgrid grid">
        <div class="field-control col-12">
          <label for="uploadfiles">{{ t('sectionsFile') }}:</label>
          <FileUpload mode="basic" accept=".xlsx" ref="file" label="Import"
                      chooseLabel="Import" class="mr-2 inline-block"
                      v-on:change="onUploadSections()"/>
        </div>
      </div>
      <template #footer>
        <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                @click="hideDialogImportSections"/>
        <Button :label="t('import')" icon="pi pi-check" class="p-button-text next_btn"
                @click="importSection()"/>
      </template>
    </Dialog>
    <Dialog v-model:visible="DocumentsitesDialog" :style="{width: '900px'}" :header="titleModalDocumentSites"
            :modal="true"
            class="modal_general p-fluid">
      <div class="grid pb-2" style="align-items: baseline;">
        <div class="col-6">
          <Dropdown v-model="valueStatusAffectDoc" :options="valuesStatusAffect" optionLabel="label"/>
        </div>
        <div class="col-6">
          <Button :label="t('confirm')" icon="pi pi-check" class="p-button-text next_btn"
                  @click="validStatusSites"
                  style=" border: 1px solid #3458a7 !important; background: #3458a7;color: white;top: 8px;width: 30%;font-weight: 400;"/>
        </div>
      </div>
      <DataTable :value="documentsLoad"
                 :totalRecords="totalItemsDocumentSites"
                 dataKey="id" :paginator="true" :rows="10"
                 class="p-datatable-gridlines table-filter-texte list_site_copy"
                 :loading="loadingCopySites"
                 responsiveLayout="scroll"
                 v-model:selection="selectedSites"
                 v-model:filters="filters"
                 :globalFilterFields="['site_path','applicable']"
                 filterDisplay="menu"
      >
        <template #loading>
          Loading data. Please wait.
        </template>

        <template #empty>
          {{ t('NoDataToDisplay') }}
        </template>
        <Column selectionMode="multiple" headerStyle="width: 1rem"></Column>

        <Column field="site_path" header="Site" style="width:900px"
                :showFilterMatchModes="false">
          <template #body="{data}">
            <div class="tooltip"
                 v-tooltip.right="'<p>'+ data?.site_path+'</p>'">
              <p>{{ data?.site_path }}</p>
            </div>
          </template>
          <template #filter="{ filterModel }">
            <InputText v-model="filterModel.value" type="text" class="p-column-filter"
                       :placeholder="t('search')"/>
          </template>
        </Column>

        <Column field="applicable" :header="t('applicability')" style="width:10rem"
                :showFilterMatchModes="false">
          <template #body="{data}">
            <p>{{ data?.applicable }} </p>
          </template>
          <template #filter="{filterModel}">
            <Dropdown v-model="filterModel.value" :options="listApplicable" placeholder=" Any "
                      class="p-column-filter" :showClear="true">
              <template #value="slotProps">
                                                <span :class="'customer-badge status-' + slotProps.value"
                                                      v-if="slotProps.value">{{ slotProps.value }}</span>
                <span v-else>{{ t('select') }}</span>
              </template>
              <template #option="slotProps">
                                                    <span :class="'customer-badge status-' + slotProps.option">{{
                                                        slotProps.option
                                                      }}</span>
              </template>
            </Dropdown>
          </template>
        </Column>


      </DataTable>
      <template #footer>
        <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                @click="hideDialogDocumentSites"/>
        <Button :label="t('save')" icon="pi pi-check" class="p-button-text next_btn"
                @click="saveSitesDoc()"/>

      </template>
    </Dialog>

    <ConfirmDialog :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}"></ConfirmDialog>
    <Toast/>
  </div>
</template>
<script>
import {useRoute} from 'vue-router';
import {Actions} from "@/store/enums/StoreEnums";
import {useStore} from "vuex";
import {useConfirm} from "primevue/useconfirm";
// import moment from "moment/moment";
import moment from 'moment-timezone'

import {useToast} from "primevue/usetoast";
import Editor from 'primevue/editor';
import {usePrimeVue} from "primevue/config";
import {computed} from "vue";
import {saveAs} from 'file-saver';
import {FilterMatchMode} from "primevue/api";

export default {
  components: {
    Editor,
  },
  name: "edit-text-general",
  data() {
    return {
      picklistValue: [[
        {name: 'San Francisco', code: 'SF'},
        {name: 'London', code: 'LDN'},
        {name: 'Paris', code: 'PRS'},
        {name: 'Istanbul', code: 'IST'},
        {name: 'Berlin', code: 'BRL'},
        {name: 'Barcelona', code: 'BRC'},
        {name: 'Rome', code: 'RM'},
      ], []],
      idDocument: '',
      sectionsText: [],
      expandedRowsSec: [],
      camplianceText: null,
      dataDocument: null,
      editTextDialog: false,
      text: {},
      sectionDialog: false,
      editComplianceDialog: false,
      section: {},
      compliance: {},
      valuesSubDomain: [],
      valueCountries: [],
      statusDocument: [],
      valuesStatusAffect: [
        {id: 0, label: 'Pour information', is_applicable: false},
        {id: 1, label: 'Avec exigences', is_applicable: true},
      ],
      titleModalSection: '',
      titleModalCompliance: '',
      idSection: '',
      listValuesSite: [[], []],
      tabCategories: [],
      valueStatusAffect: {id: 0, label: 'Pour information', is_applicable: false},
      totalItemsSection: '',
      loadingSection: true,
      totalItemsCompliance: '',
      loadingCompliance: {},
      valueSubDomain: '',
      dated_at: '',
      last_updated_at: '',
      activeIndex: 0,
      submitted: false,
      submittedText: false,
      valuesDefaultApp: [
        {value: 1, label: 'Avec exigences'},
        {value: 2, label: 'Pour information'}
      ],
      valueDefaultApp: '',
      statusText: '',
      countryText: '',
      typeDocument: '',
      typesDocument: [
        {
          "Type": "Directive",
          "TypeId": "72"
        },
        {
          "Type": "Décision",
          "TypeId": "70"
        },
        {
          "Type": "Règlement",
          "TypeId": "36"
        },
        {
          "Type": "Avis",
          "TypeId": "66"
        },
        {
          "Type": "Code de l'environnement",
          "TypeId": "86"
        },
        {
          "Type": "Arrêté",
          "TypeId": "9"
        },
        {
          "Type": "Circulaire",
          "TypeId": "97"
        },
        {
          "Type": "Décret",
          "TypeId": "71"
        },
        {
          "Type": "Code de la santé publique",
          "TypeId": "109"
        },
        {
          "Type": "Autre exigence",
          "TypeId": "107"
        },
        {
          "Type": "Plan",
          "TypeId": "255"
        },
        {
          "Type": "Courrier",
          "TypeId": "69"
        },
        {
          "Type": "Code de la construction et de l'habitation ",
          "TypeId": "141"
        },
        {
          "Type": "SDAGE",
          "TypeId": "121"
        },
        {
          "Type": "Loi",
          "TypeId": "73"
        },
        {
          "Type": "Code de l'urbanisme",
          "TypeId": "110"
        },
        {
          "Type": "Code général des collectivités territoriales",
          "TypeId": "166"
        },
        {
          "Type": "Note",
          "TypeId": "99"
        },
        {
          "Type": "RSD",
          "TypeId": "120"
        },
        {
          "Type": "Plan d'Occupation des Sols",
          "TypeId": "115"
        },
        {
          "Type": "Plan Local d'Urbanisme",
          "TypeId": "116"
        },
        {
          "Type": "Code du commerce",
          "TypeId": "68"
        },
        {
          "Type": "Code des douanes",
          "TypeId": "112"
        },
        {
          "Type": "Code rural et de la pêche maritime",
          "TypeId": "113"
        },
        {
          "Type": "Arrêté-type",
          "TypeId": "95"
        },
        {
          "Type": "Arrêté de prescriptions",
          "TypeId": "278"
        },
        {
          "Type": "Arrêté de prescription",
          "TypeId": "91"
        },
        {
          "Type": "Arrêté sectoriel",
          "TypeId": "94"
        },
        {
          "Type": "Arrêté d'enregistrement",
          "TypeId": "92"
        },
        {
          "Type": "Code du travail",
          "TypeId": "98"
        },
        {
          "Type": "PPRI",
          "TypeId": "117"
        },
        {
          "Type": "Arrêté préfectoral",
          "TypeId": "93"
        },
        {
          "Type": "Instruction",
          "TypeId": "130"
        },
        {
          "Type": "Convention",
          "TypeId": "114"
        },
        {
          "Type": "Code Forestier",
          "TypeId": "123"
        },
        {
          "Type": "Code des coll. Terr.",
          "TypeId": "111"
        },
        {
          "Type": "Code du patrimoine",
          "TypeId": "122"
        },
        {
          "Type": "Rapport",
          "TypeId": "119"
        },
        {
          "Type": "Liste",
          "TypeId": "168"
        },
        {
          "Type": "Communication",
          "TypeId": "128"
        },
        {
          "Type": "Recommandation CNAMTS",
          "TypeId": "131"
        },
        {
          "Type": "Code pénal",
          "TypeId": "127"
        },
        {
          "Type": "Code de la sécurité sociale",
          "TypeId": "126"
        },
        {
          "Type": "Code civil",
          "TypeId": "125"
        },
        {
          "Type": "Accord",
          "TypeId": "124"
        },
        {
          "Type": "Procès-verbal",
          "TypeId": "118"
        },
        {
          "Type": "Rubriques ICPE",
          "TypeId": "170"
        },
        {
          "Type": "DDRM",
          "TypeId": "171"
        },
        {
          "Type": "PPRT",
          "TypeId": "172"
        },
        {
          "Type": "Code de l'énergie",
          "TypeId": "142"
        },
        {
          "Type": "Orientations",
          "TypeId": "201"
        },
        {
          "Type": "Code de l'aviation civile",
          "TypeId": "202"
        },
        {
          "Type": "Code des transports",
          "TypeId": "67"
        },
        {
          "Type": "Code général des impôts",
          "TypeId": "203"
        },
        {
          "Type": "Résolution",
          "TypeId": "205"
        },
        {
          "Type": "Code des collectivités territoriales",
          "TypeId": "204"
        },
        {
          "Type": "Code de la route",
          "TypeId": "37"
        },
        {
          "Type": "Code rural",
          "TypeId": "140"
        },
        {
          "Type": "Grille d'audit",
          "TypeId": "208"
        },
        {
          "Type": "Règlement ERP",
          "TypeId": "210"
        },
        {
          "Type": "Instruction technique",
          "TypeId": "209"
        },
        {
          "Type": "Ordonnance Fédérale",
          "TypeId": "213"
        },
        {
          "Type": "Loi Fédérale",
          "TypeId": "212"
        },
        {
          "Type": "Loi Cantonale",
          "TypeId": "211"
        },
        {
          "Type": "Règlement cantonal",
          "TypeId": "227"
        },
        {
          "Type": "Recommandation",
          "TypeId": "226"
        },
        {
          "Type": "Autres exigences",
          "TypeId": "215"
        },
        {
          "Type": "Constitution Fédérale",
          "TypeId": "216"
        },
        {
          "Type": "Arrêté de mise en demeure",
          "TypeId": "217"
        },
        {
          "Type": "Code du travail applicable à Mayotte",
          "TypeId": "218"
        },
        {
          "Type": "Contrat",
          "TypeId": "220"
        },
        {
          "Type": "Directive cantonale",
          "TypeId": "222"
        },
        {
          "Type": "Code de la consommation",
          "TypeId": "221"
        },
        {
          "Type": "Ordonnance",
          "TypeId": "225"
        },
        {
          "Type": "Projet",
          "TypeId": "224"
        },
        {
          "Type": "Charte Lacroix",
          "TypeId": "229"
        },
        {
          "Type": "Code de la défense",
          "TypeId": "239"
        },
        {
          "Type": "SAGE",
          "TypeId": "243"
        },
        {
          "Type": "Délibération",
          "TypeId": "129"
        },
        {
          "Type": "Information",
          "TypeId": "245"
        },
        {
          "Type": "Norme",
          "TypeId": "254"
        },
        {
          "Type": "PPRN",
          "TypeId": "257"
        },
        {
          "Type": "PPRif",
          "TypeId": "256"
        },
        {
          "Type": "Shéma",
          "TypeId": "258"
        },
        {
          "Type": "Note d'information",
          "TypeId": "259"
        },
        {
          "Type": "Lois",
          "TypeId": "260"
        },
        {
          "Type": "RGPT",
          "TypeId": "263"
        },
        {
          "Type": "Code du bien-être au travail",
          "TypeId": "262"
        },
        {
          "Type": "Arrêté royal",
          "TypeId": "264"
        },
        {
          "Type": "Guide",
          "TypeId": "267"
        },
        {
          "Type": "UK Statutory Instruments",
          "TypeId": "273"
        },
        {
          "Type": "UK Public General Acts",
          "TypeId": "272"
        },
        {
          "Type": "UK Statutory Instruments",
          "TypeId": "271"
        },
        {
          "Type": "Protocole",
          "TypeId": "275"
        },
        {
          "Type": "PPR",
          "TypeId": "276"
        },
        {
          "Type": "Code Minier",
          "TypeId": "277"
        },
        {
          "Type": "Code des eaux",
          "TypeId": "279"
        },
        {
          "Type": "CSPRIEPB",
          "TypeId": "289"
        },
        {
          "Type": "Code de l'aménagement du territoire et de l'urbanisme",
          "TypeId": "282"
        },
        {
          "Type": "Code des hydrocarbures",
          "TypeId": "283"
        },
        {
          "Type": "Code des ports maritimes",
          "TypeId": "284"
        },
        {
          "Type": "Code d'incitation aux investissements",
          "TypeId": "286"
        },
        {
          "Type": "Code des marchés publics",
          "TypeId": "288"
        },
        {
          "Type": "Note technique",
          "TypeId": "290"
        },
        {
          "Type": "Dahir",
          "TypeId": "292"
        },
        {
          "Type": "Recommandations",
          "TypeId": "214"
        },
        {
          "Type": "Arrêté viziriel",
          "TypeId": "291"
        },
        {
          "Type": "Projet",
          "TypeId": "280"
        },
        {
          "Type": "Code de la contruction et de l'habitation",
          "TypeId": "265"
        },
        {
          "Type": "Code de l'eau",
          "TypeId": "268"
        },
        {
          "Type": "Loi organique",
          "TypeId": "287"
        },
        {
          "Type": "Code de la sécurité intérieure",
          "TypeId": "314"
        },
        {
          "Type": "Code de la voirie routière",
          "TypeId": "313"
        }
      ],
      pageSections: 1,
      pageCompliance: 1,
      evaluationStatus: '',
      valuesEvaluations: [],
      titleSection: '',
      last_update_compliance: '',
      categoriesSubDmainAdd: [],
      importSectionsDialog: false,
      btnComplianceSubmit: false,
      btnSectionSubmit: false,
      btnDocumentSubmit: false,
      summaryFile: null,
      illustrationFile: null,
      urlDoc: '',
      newIdDoc: '',
      summary_file: '',
      illustrations_file: '',
      liveStatusColor: '',
      documents: [],
      selectedDocument: '',
      idDocumentSelected: '',
      loadingCopySites: false,
      titleModalDocumentSites: '',
      documentsLoad: [],
      totalItemsDocumentSites: 0,
      DocumentsitesDialog: false,
      selectedSites: [],
      valueStatusAffectDoc: {id: 0, label: 'Pour information', is_applicable: false},
      filters: null,
      listApplicable: ['Pour information', 'Avec exigences'],
      inputTargetLib: '',
      inputSourceApp: '',
    }
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const confirm = useConfirm();
    const toast = useToast();
    const primevue = usePrimeVue();
    const user = computed(() => {
      return store.getters.currentUser
    });
    let user_id = user.value?.id;
    const countStatusImport = computed(() => {
      return store.getters.toolsObject.countStatusImport
    })
    return {
      store,
      route,
      confirm,
      toast,
      primevue,
      t: primevue.config.StoreTranslation.t,
      user_id,
      countStatusImport
    };
  },

  async beforeMount() {
    this.idDocument = this.route.params.id;
    await this.getSites()
    await this.getDocument()
    await this.getStatusImport()
    const countInterval = setInterval(() => {
      this.getStatusImport()
    }, 30000)

    await this.getCategoriesByTheme();

    await this.getCountries();
    if (this.sectionsText.length < 1) {
      await this.getSections(this.idDocument);
    }
    this.countInterval = countInterval
  },
  mounted() {
    this.typesDocument.sort((a, b) => a.Type?.localeCompare(b.Type));
    this.getStatusEvaluation();
  },
  beforeUnmount() {
    clearInterval(this.countInterval)
  },
  watch: {
    countStatusImport() {
      if (this.countStatusImport < 1) {
        this.loadingSection = false
        this.expandedRowsSec = [];
        this.getSections(this.idDocument);
      } else {
        this.loadingSection = true
      }
    }
  },
  created() {
    this.initFilters1()
  },
  methods: {
    initFilters1() {
      this.filters = {
        'site_path': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'applicable': {value: null, matchMode: FilterMatchMode.EQUALS}
      }
    },
    backTotexte() {
      this.$router.push('/admin-text');
    },
    onRowExpand(id_section, page = 1) {
      this.loadingCompliance[id_section] = true;
      this.store.dispatch(Actions.GET_COMPLIANCES, {
        'section': id_section,
        "page": page,
        'customHeader': true,
      }).then(data => {
        this.camplianceText = data['hydra:member']
        this.sectionsText.find((element, index) => {
          if (element.id == id_section) {
            this.camplianceText = this.camplianceText.map((data) => ({
              ...data,

              id_section: id_section,
              titleSection: element.title,
            }));
            Object.assign(this.sectionsText[index], {"compliances": this.camplianceText})
          }
        })
        this.totalItemsCompliance = data['hydra:totalItems']
        this.loadingCompliance[id_section] = false;
      })
    },
    getSections(idDoc) {
      this.loadingSection = true
      this.expandedRowsSec = []
      return this.store.dispatch(Actions.GET_SECTIONS, {
        'document': idDoc,
        'page': this.pageSections,
        'customHeader': true
      }).then(data => {
        this.sectionsText = data['hydra:member']
        this.totalItemsSection = data['hydra:totalItems']
        // this.getStatusImport()
        if (this.countStatusImport < 1) {
          this.loadingSection = false
        } else {
          this.loadingSection = true
        }
      })
    },
    onRowReorder(event) {
      this.sectionsText = event.value;
      this.$toast.add({severity: 'success', summary: this.t('rowReordered'), life: 3000});
    },
    modalEditText() {
      this.submittedText = false
      this.getSiteDocument(this.dataDocument)
      this.getDocument()
      this.getStatusDocument();
      this.editTextDialog = true
      this.$nextTick(() => {
        this.addDoubleClickListener()
        this.removeDoubleClickListener()
      });
    },
    editText() {
      let affectations_site = [];
      if (this.valueSubDomain) {
        this.text.sub_domain = '/api/categories/' + Object.keys(this.valueSubDomain)
        let objectSubDomain = this.categoriesSubDmainAdd.find(item => item.id == Object.keys(this.valueSubDomain))
        this.text.domain = '/api/categories/' + objectSubDomain.data.id_domain
        this.text.theme = '/api/categories/' + objectSubDomain.data.id_theme
      }
      if (this.countryText) {
        this.text.country = '/api/countries/' + this.countryText.id
      }
      if (moment(this.dated_at).isValid()) {
        this.text.dated_at = moment(this.dated_at).format()
      }

      if (!this.last_updated_at)
        this.text.last_updated_at = null

      if (moment(this.last_updated_at).isValid()) {
        this.text.last_updated_at = moment(this.last_updated_at).format()
      }


      if (this.statusText) {
        this.text.status = this.statusText.id
      }
      this.listValuesSite[0].forEach(element => {
        let idSite = 'api/sites/' + element.id
        affectations_site.push({
          'site': idSite,
          'is_applicable': element.valueLabel,
          created_by: 'api/users/' + this.user_id,
        })
      })
      this.text.affectations = affectations_site
      if (this.valueDefaultApp.value === 1) {
        this.text.is_applicable = true
      } else {
        this.text.is_applicable = false
      }
      if (this.typeDocument) {
        this.text.document_type = this.typeDocument.TypeId
      }
      this.text.url = this.urlDoc
      this.text.updated_by = 'api/users/' + this.user_id
      this.submittedText = true;
      if (this.text.title && this.text.status && this.text.document_type && this.text.sub_domain) {
        this.btnDocumentSubmit = true
        this.store.dispatch(Actions.PUT_DOCUMENT, this.text).then(() => {
          this.btnDocumentSubmit = false
          this.editTextDialog = false
          this.toast.add({
            severity: 'success',
            detail: this.t('documentHasSuccessfullyModified'),
            life: 3000
          });
          this.newIdDoc = this.idDocument
          if (this.summaryFile) {
            this.store.dispatch(Actions.UPLOAD_FILE, {
              type: 'document',
              fileName: this.summaryFile,
              idDoc: this.newIdDoc,
              uploadedBy: this.user_id,
              typeDocument: 'summaryFile'
            }).then(() => {
              this.toast.add({
                severity: 'success',
                detail: this.t('fileSuccessfullyUploaded'),
                life: 3000
              });
            })
          }
          if (this.illustrationFile) {
            this.store.dispatch(Actions.UPLOAD_FILE, {
              type: 'document',
              fileName: this.illustrationFile,
              idDoc: this.newIdDoc,
              uploadedBy: this.user_id,
              typeDocument: 'illustrationFile'
            }).then(() => {
              this.toast.add({
                severity: 'success',
                detail: this.t('fileSuccessfullyUploaded'),
                life: 3000
              });
            })
          }
          this.getDocument()
        })
      }
    },
    modalSection(dataSection) {
      this.section = {}
      this.sectionDialog = true
      if (dataSection) {
        this.titleModalSection = this.t('editSection')
        this.store.dispatch(Actions.GET_SECTION_BY_ID, {id: dataSection.id, customHeader: false}).then(data => {
          this.section.title = data.title
          this.section.id = data.id
          this.section.ranked = data.ranked
        })
      } else {
        this.titleModalSection = this.t('addASection')

      }
    },
    modalCompliance(Obj, type) {
      this.compliance = {}
      this.evaluationStatus = ''
      this.last_update_compliance = ''
      this.submitted = false
      this.editComplianceDialog = true
      this.titleSection = Obj.titleSection

      if (type === 'edit') {
        this.titleModalCompliance = this.t('editCompliance') + '(Section:' + this.titleSection + ' )'

        this.store.dispatch(Actions.GET_COMPLIANCE_BY_ID, {id: Obj.id, 'customHeader': false}).then(data => {

          this.compliance.ranked = data.ranked
          this.compliance.title = data.title
          this.compliance.id = data.id

          if (data.status) {
            this.evaluationStatus = this.valuesEvaluations.find(element =>
                element.id == data.status.id
            )
          }

          if (data.last_update_compliance) {
            this.last_update_compliance = new Date(moment(data.last_update_compliance).format('yy-MM-DD'))
          }
          this.compliance.annotation_compliance = data.annotation_compliance
          this.idSection = Obj.id_section
          this.compliance.title_rte = data.title_rte
        })
      } else {

        this.idSection = Obj.id
        this.titleModalCompliance = this.t('addACompliance')
      }
    },
    getCategoriesByTheme() {
      let tabCategoriesTheme = []
      let tabCategoriesDomain = []
      let tabCategoriesSubDomain = []

      // this.store.dispatch(Actions.GET_CATEGORIES, {type:'THEME','customHeader': false,}).then(data => {
      this.store.dispatch(Actions.GET_CATEGORIES_TREE, {'customHeader': false,}).then(data => {


        var formatSelectTree = (obj, indexCh = null, parent) => {


          obj.forEach((element, index) => {
            let indexF = '0';
            if (null === indexCh) {
              indexF = index + '';
            } else {
              indexF = indexCh + '-' + index
            }

            if (Object.prototype.hasOwnProperty.call(element, 'categories')) {
              formatSelectTree(element.categories, indexF, element)

              if (element.type === 'THEME') {
                tabCategoriesTheme.push(element)
              } else if (element.type === 'DOMAIN') {
                tabCategoriesDomain.push(element)
              }
              // else {
              //   tabCategoriesSubDomain.push(element)
              // }
            } else {
              if (element.type === 'SUB_DOMAIN') {
                tabCategoriesSubDomain.push(element)
              }
            }

            Object.assign(element, {"children": element.categories})
            //  Object.assign(element, {"label":element.name})
            Object.assign(element, {"icon": 'pi pi-fw pi-inbox'})
            Object.assign(element, {"label": element.name})

            Object.assign(element, {"key": element.id})
            if (element.type === 'THEME') {
              Object.assign(obj[index], {
                "data": {
                  "id": element.id,
                  "name": element.name,
                  "type": element.type,
                  "code": element.code,

                }
              })
            } else if (element.type === 'DOMAIN') {
              Object.assign(obj[index], {
                "data": {
                  "id": element.id,
                  "name": element.name,
                  "type": element.type,
                  "code": element.code,
                  "id_theme": parent.id,

                }
              })
            } else {
              Object.assign(obj[index], {
                "data": {
                  "id": element.id,
                  "name": element.name,
                  "type": element.type,
                  "code": element.code,
                  "id_theme": parent.parent_id,
                  "id_domain": parent.id,

                }
              })
            }
            // parent= obj[index]

          });
          return obj;
        };
        this.tabCategories = formatSelectTree(data)
        this.tabCategories.sort((a, b) => a.name?.localeCompare(b.name));
        this.tabCategories.forEach(element => {
          element.categories.sort((a, b) => a.name?.localeCompare(b.name))
          element.categories.forEach(domain => {
            domain.categories.sort((a, b) => a.name?.localeCompare(b.name))
          })
        })

        this.categoriesTheme = tabCategoriesTheme;
        this.categoriesDomain = tabCategoriesDomain;
        this.categoriesSubDomain = tabCategoriesSubDomain;
        this.categoriesSubDmainAdd = tabCategoriesSubDomain;
        this.valuesSubDomain = tabCategoriesSubDomain;
        // this.dropdownValuesTheme = tabCategoriesTheme
      })
    },
    getCountries() {
      this.store.dispatch(Actions.GET_COUNTRIES, {'customHeader': false,}).then(data => {
        this.valueCountries = data;
      })
    },
    async editCompliance() {
      this.submitted = true
      if (this.evaluationStatus) {
        if (this.evaluationStatus.id) {
          this.compliance['status'] = '/api/evaluation_statuses/' + this.evaluationStatus.id;
        } else {
          this.compliance['status'] = '/api/evaluation_statuses/' + 53;
        }
      }
      if (moment(this.last_update_compliance).isValid()) {
        this.compliance.last_update_compliance = moment(this.last_update_compliance).format()
      } else if (!this.last_update_compliance) {
        this.compliance.last_update_compliance = null
      }
      this.compliance.updated_by = 'api/users/' + this.user_id
      if (this.compliance.title && this.compliance.ranked) {
        this.btnComplianceSubmit = true
        this.store.dispatch(Actions.PUT_COMPLIANCE, this.compliance).then(async () => {
          this.btnComplianceSubmit = false
          this.toast.add({
            severity: 'success',
            detail: this.t('ComplianceHasSuccessfullyModified'),
            life: 3000
          });
          this.loadingSection = true
          this.editComplianceDialog = false
          await this.getDocument();
          await this.getSections(this.idDocument);
          await this.refreshCompliances(this.idSection)
        })
      }
    },
    addCompliance() {
      this.submitted = true
      this.compliance['section'] = 'api/sections/' + this.idSection;
      this.compliance['document'] = 'api/documents/' + this.idDocument;
      if (this.evaluationStatus.id) {
        this.compliance['status'] = '/api/evaluation_statuses/' + this.evaluationStatus.id;
      }
      if (moment(this.last_update_compliance).isValid()) {
        this.compliance.last_update_compliance = moment(this.last_update_compliance).format()
      }
      if (this.compliance.title && this.compliance.ranked) {
        this.btnComplianceSubmit = true
        this.store.dispatch(Actions.ADD_COMPLIANCE, this.compliance).then(async () => {
          this.toast.add({
            severity: 'success',
            detail: this.t('complianceHasSuccessfullyAdded'),
            life: 3000
          });
          this.editComplianceDialog = false
          this.submitted = false
          this.btnComplianceSubmit = false
          await this.getDocument();
          await this.getSections(this.idDocument);
          await this.refreshCompliances(this.idSection, 'add')

        })
      }
    },
    refreshCompliances(idSection, typeAction) {
      this.expandedRowsSec = []
      this.loadingSection = true
      this.loadingCompliance[idSection] = true;
      let findSec = this.sectionsText?.find((d) => d.id == idSection);
      if (findSec) {
        if (typeAction === 'delete' && findSec?.compliances?.length < 2) {
          this.expandedRowsSec = this.expandedRowsSec.filter((s) => s.id !== idSection);
        } else if (typeAction === 'delete' && findSec?.compliances?.length > 1) {
          this.expandedRowsSec = this.sectionsText.filter((s) => s.id === idSection);
        } else {
          this.expandedRowsSec = this.sectionsText.filter((s) => s.id === idSection);
        }
        if (typeAction === 'add') {
          findSec.compliances_count = findSec.compliances_count + 1
          this.rowClass(findSec)
        }
      }
      return this.store.dispatch(Actions.GET_COMPLIANCES, {
        'section': idSection,
        "page": this.pageCompliance,
        'customHeader': true,
      }).then(data => {
        this.loadingSection = false
        //  this.sectionsText = data
        this.camplianceText = data['hydra:member']
        this.sectionsText.find((element, index) => {
          if (element.id == idSection) {
            this.camplianceText = this.camplianceText.map((data) => ({
              ...data,
              id_section: idSection,
              titleSection: element.title,
            }));
            Object.assign(this.sectionsText[index], {"compliances": this.camplianceText})
          }
        })
        this.totalItemsCompliance = data['hydra:totalItems']
        this.loadingCompliance[idSection] = false;
      })
    },
    editSection() {
      this.submitted = true
      if (this.section.title) {
        this.btnSectionSubmit = true
        this.store.dispatch(Actions.PUT_RANKED_SECTION, this.section).then(() => {
          this.toast.add({
            severity: 'success',
            detail: this.t('SectionHasSuccessfullyModified'),
            life: 3000
          });
          this.onPageSection(this.idDocument, this.pageSections)
          this.sectionDialog = false
          this.btnSectionSubmit = false

        })
      }
    },
    hideDialog() {
      this.editComplianceDialog = false
      this.sectionDialog = false
      this.editTextDialog = false
    },
    deleteSection(dataSection) {
      this.$confirm.require({
        message: this.t('AreYouSureYouWantToDelete'),
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.store.dispatch(Actions.DELETE_SECTION, dataSection.id).then(() => {
            this.sectionsText = this.sectionsText.filter(item => !(item.id === dataSection.id))
            this.toast.add({
              severity: 'success',
              detail: this.t('SectionHasSuccessfullyDeleted'),
              life: 3000
            });
            this.onPageSection(this.idDocument, this.pageSections)
          })
        },
        reject: () => {
        },
        onHide: () => {
        }
      });
    },
    deleteCompliance(dataCompliance, idSection) {
      this.confirm.require({
        message: this.t('AreYouSureYouWantToDelete'),
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.store.dispatch(Actions.DELETE_COMPLIANCE, dataCompliance.id).then(() => {
            this.toast.add({
              severity: 'success',
              detail: this.t('ComplianceHasSuccessfullyDeleted'),
              life: 3000
            });
            this.refreshCompliances(idSection, 'delete')
          })
        },
        reject: () => {
        },
        onHide: () => {
        }
      });
    },
    addSection() {
      this.submitted = true
      this.section.document = 'api/documents/' + this.idDocument;
      if (this.section.title) {
        this.btnSectionSubmit = true
        this.store.dispatch(Actions.POST_RANKED_SECTION, this.section).then(() => {
          this.sectionDialog = false
          this.toast.add({
            severity: 'success',
            detail: this.t('sectionHasSuccessfullyAdded'),
            life: 3000
          });
          this.onPageSection(this.idDocument, this.pageSections)
          this.submitted = false
          this.btnSectionSubmit = false
        })
      }

    },
    getStatusDocument() {
      this.store.dispatch(Actions.GET_STATUS_DOCUMENT).then(data => {
        this.statusDocument = data
        this.statusDocument = this.statusDocument.filter(item => item.id !== 54)

        let objStatus = this.statusDocument.find(x =>
            x.id == this.dataDocument.status
        )
        this.statusText = objStatus
      })
    },

    getSites() {
      this.listValuesSite[0] = []
      this.treeSelectNodes = []
      return this.store.dispatch(Actions.GET_CUSTOMERS_SITE_TREE, {'customHeader': false}).then(data => {
        function addConcatName(arr, parentCode = null) {
          arr.forEach(obj => {
            const childSiteName = obj.site_name || '';
            if (parentCode) {
              obj.concat_name = `${parentCode}-${childSiteName}`;
            }
            if (!parentCode) {
              obj.concat_name = obj.name;
            }
            if (obj.childrens) {
              addConcatName(obj.childrens, obj.concat_name);
            }
          });
        }

        let ob = data;
        addConcatName(ob);
        let entity_name = ''
        var formatSelectTree = (obj, indexCh = null, parent) => {
          obj?.forEach((element, index) => {
            let indexF = '0';
            if (null === indexCh) {
              indexF = index + '';
            } else {
              indexF = indexCh + '-' + index
            }
            if (Object.prototype.hasOwnProperty.call(element, 'childrens')) {
              formatSelectTree(element.childrens, indexF, element)
            }
            Object.assign(element, {"children": element.childrens})
            if (Object.prototype.hasOwnProperty.call(element, 'site_name')) {
              Object.assign(element, {"label": element.site_name})
              element.id = element.site_id
            } else {
              Object.assign(element, {"label": element.name})
            }
            Object.assign(element, {"icon": 'pi pi-fw pi-inbox'})
            Object.assign(element, {"key": element.id})
            if (element.entity_type === 'CUSTOMER') {
              Object.assign(obj[index], {
                "data": {
                  "id": element.id,
                  "name": element.name,
                  "type": element.type,
                  "code": element.code,
                  "customer_name": element.name,
                }
              })
            } else if (element.entity_type === 'entity') {
              entity_name = entity_name + element.code
              Object.assign(obj[index], {
                "data": {
                  "id": element.id,
                  "name": element.name,
                  "type": element.type,
                  "code": element.code,
                  "customer_name": parent.name,
                }
              })
            } else {
              if (parent.site_name) {
                Object.assign(obj[index], {
                  "data": {
                    "id": element.id,
                    "name": element.name,
                    "type": element.type,
                    "code": element.code,
                    "typeLabel": element.site_name,
                    "customer_name": parent.name,
                    "entity_name": parent.site_name,
                    "site_name": element.site_name,
                    "concatSitesName": element.concat_name
                  }
                })
              } else {
                Object.assign(obj[index], {
                  "data": {
                    "id": element.id,
                    "name": element.name,
                    "type": element.type,
                    "code": element.code,
                    "typeLabel": element.site_name,
                    "customer_name": parent.name,
                    "entity_name": parent.site_name,
                    "site_name": element.site_name,
                    "concatSitesName": element.concat_name
                  }
                })
              }
              this.treeSelectNodes.push(obj[index].data)
            }
          });
          return obj;
        };
        formatSelectTree(ob);
      })
    },
    getSiteDocument(document) {
      this.listValuesSite[1] = this.treeSelectNodes
      this.listValuesSite[0] = document.listValueSite?.length > 0 ? document.listValueSite : []
      document.affectations.forEach(element => {
        let elmeSite = this.listValuesSite[1].find(item => item.id == element.site.id)
        element.site.name = element.site.name + '';
        element.site.is_applicable = element.is_applicable;
        if (element.site.is_applicable && elmeSite) {
          element.site.typeLabel = 'Avec exigences'
          elmeSite.typeLabel = 'Avec exigences'
          elmeSite.valueLabel = true
        } else if (elmeSite) {
          element.site.typeLabel = 'Pour information'
          elmeSite.typeLabel = 'Pour information'
          elmeSite.valueLabel = false
        }
        if (elmeSite)
          this.listValuesSite[0].push(elmeSite)
        this.listValuesSite[1] = this.listValuesSite[1].filter(item => !(item.id === element.site.id))
      })
    },
    moveToTarget() {
    },
    moveToSource(move) {
      for (let i = 0; i < move.items?.length; i++) {
        move.items[i].typeLabel = this.valueStatusAffect.label;
        move.items[i].valueLabel = this.valueStatusAffect.is_applicable;
      }

    },
    moveAllToTarget(move) {
      const listValuesSite = this.listValuesSite
      this.$nextTick(() => {
      let ul = document.getElementsByClassName("p-picklist-list p-picklist-source");
      let li = ul[0].getElementsByTagName("li");

      let filteredItems = [];

      for (let i = 0; i < li.length; i++) {
        if (li[i].dataset?.filtered == 'true') {

          let itemCode = li[i].dataset?.key;
          let item = listValuesSite[0].find(item => item.id == itemCode);
          if (item) {
            filteredItems.push(item);
          }
        }
      }

        if (filteredItems.length > 0) {
          move.items = filteredItems
          this.listValuesSite = [
            listValuesSite[0].filter(item => !filteredItems.includes(item)),
            [...listValuesSite[1],...filteredItems]
          ];
          document.getElementById("myInputSource").value = '';
          document.getElementById("myInputSApp").value = '';
          this.filterSites('source')
        }


      });
    },

    moveAllToSource(move) {

      const listValuesSite = this.listValuesSite
      this.$nextTick(() => {
        let ul = document.getElementsByClassName("p-picklist-list p-picklist-target");
        let li = ul[0].getElementsByTagName("li");

        let filteredItems = [];

        Array.from(li).forEach((element) => {
          if (element.dataset?.filtered == 'true') {
            let itemCode = element.dataset?.key;
            let item = this.listValuesSite[0].find(item => item.id == itemCode);
            if (item) {
              item.typeLabel = this.valueStatusAffect.label;
              item.valueLabel = this.valueStatusAffect.is_applicable;
              filteredItems.push(item);
            }
          }
        })
        if (filteredItems.length > 0) {
          move.items = filteredItems
            this.listValuesSite = [
              [...listValuesSite[0], ...filteredItems],
              listValuesSite[1].filter(item => !filteredItems.includes(item))
            ];
          document.getElementById("myInputLib").value = '';
          document.getElementById("myInput").value = '';
          this.filterSites('target')
        }
      });
    },
    onPageSection(idDoc, page) {
      this.pageSections = page
      this.getSections(this.idDocument)
    },
    onpageCompliance(idSection, page) {
      this.pageCompliance = page
      this.onRowExpand(idSection, page);
    },
    truncate(source) {
      if (source) {
        let size
        if (screen.width <= 325) {
          size = 25
          return source.length > size ? source.slice(0, size - 1) + " …" : source;
        } else if (screen.width <= 425) {
          size = 35
          return source.length > size ? source.slice(0, size - 1) + " …" : source;
        } else if (screen.width <= 768) {
          size = 100
          return source.length > size ? source.slice(0, size - 1) + " …" : source;

        } else {
          size = 150
          return source.length > size ? source.slice(0, size - 1) + " …" : source;
        }
      }
    },
    truncateSiteName(source, size) {
      if (source) {
        return source.length > size ? source.slice(0, size - 1) + " …" : source;
      }
    },
    getDocument() {
      return this.store.dispatch(Actions.GET_DOCUMENT_BY_ID, {
        id: this.idDocument,
        'customHeader': false
      }).then(data => {
        this.dataDocument = data
        this.text = data
        this.summary_file = data.summary_file
        this.illustrations_file = data.illustrations_file
        delete this.text.illustrations_file
        delete this.text.summary_file


        if (this.dataDocument.is_applicable) {
          this.valueDefaultApp = {value: 1, label: 'Avec exigences'}
        } else {
          this.valueDefaultApp = {value: 2, label: 'Pour information'}
        }
        if (this.dataDocument.sub_domain) {
          this.valueSubDomain = {[this.dataDocument.sub_domain.id]: true}
        }
        if (this.dataDocument.country) {
          let objCountry = this.valueCountries.find(x => x.id == this.dataDocument.country.id)
          this.countryText = objCountry
        }
        if (moment(this.dataDocument.dated_at).isValid()) {
          this.dated_at = new Date(moment(this.dataDocument.dated_at).format('yy-MM-DD'))
        }
        if (moment(this.dataDocument.last_updated_at).isValid()) {
          this.last_updated_at = new Date(moment(this.dataDocument.last_updated_at).format('yy-MM-DD'))
        }
        if (this.dataDocument.document_type) {
          let objDocument = this.typesDocument.find(x => x.TypeId == this.dataDocument.document_type)
          this.typeDocument = objDocument
        }
        this.urlDoc = data.url
        this.liveStatusColor = data.live_status?.color

      })
    },
    selectNode(selected) {
      if (selected.children?.length) {
        setTimeout(() => {
          this.valueSubDomain = {[selected.id]: false}
        }, 100);
      }
    },
    rowClass(rowData) {
      return rowData.compliances_count > 0 ? "" : "no-expander";
    },
    getStatusEvaluation() {
      this.store.dispatch(Actions.GET_STATUS_EVALUATION, {'customHeader': false}).then(data => {
        this.valuesEvaluations = data
        this.valuesEvaluations = this.valuesEvaluations.filter(item => item.id === 51 || item.id === 54)
        this.valuesEvaluations.unshift({id: 0, name: ''})
      }).catch(() => {
      })
    },
    filterSites(position) {
      var input, filter, ul, li, a, i, txtValue, j, filter_target_lib, filter_source_app;
      if (position == 'targetLib') {
        this.inputTargetLib = document.getElementById("myInputLib");
      }
      if (position == 'sourceApp') {
        this.inputSourceApp = document.getElementById("myInputSApp");
      }
      input = ['source', 'sourceApp'].includes(position) ? document.getElementById("myInputSource") : document.getElementById("myInput");


      filter = input?.value.toUpperCase();
      filter_target_lib = this.inputTargetLib?.value?.toUpperCase();
      filter_source_app = this.inputSourceApp?.value?.toUpperCase();

      let filter_words_target = filter?.split(' ');
      let filter_words_source = filter?.split(' ');
      let filter_target_lib_words = filter_target_lib?.split(' ');
      let filter_source_app_words = filter_source_app?.split(' ');

      filter_words_target = filter_words_target?.filter(function (element) {
        return element !== '';
      });
      filter_words_source = filter_words_source?.filter(function (element) {
        return element !== '';
      });
      filter_target_lib_words = filter_target_lib_words?.filter(function (element) {
        return element !== '';
      });

      filter_source_app_words = filter_source_app_words?.filter(function (element) {
        return element !== '';
      });


      if (filter_target_lib_words?.length > 0 && ['target', 'targetLib'].includes(position)) {
        if (filter_words_target)
          filter_words_target = filter_words_target.concat(filter_target_lib_words)
      }

      if (filter_source_app_words?.length > 0 && ['source', 'sourceApp'].includes(position)) {
        if (filter_words_source)
          filter_words_source = filter_words_source.concat(filter_source_app_words)
      }
      ul = ['source', 'sourceApp'].includes(position) ? document.getElementsByClassName("p-picklist-list p-picklist-source") : document.getElementsByClassName("p-picklist-list p-picklist-target");
      li = ul[0].getElementsByTagName("li");
      // li = ul[0];

      if (filter_words_target.length > 0 && ['target', 'targetLib'].includes(position)) {
        for (j = 0; j < filter_words_target.length; j++) {
          for (i = 0; i < li.length; i++) {

            a = li[i].getElementsByTagName("div")[0];

            let id_site = li[i].querySelector('div[data-key]')?.getAttribute('data-key')
            if ((j === 0) || (j > 0 && li[i].style.display !== "none")) {
              txtValue = a.textContent || a.innerText;
              if (txtValue.toUpperCase().indexOf(filter_words_target[j]) > -1) {
                li[i].style.display = "";
                li[i].dataset.filtered = true;
                li[i].dataset.key = id_site;
              } else {
                li[i].style.display = "none";
                li[i].dataset.filtered = false;
                li[i].dataset.key = id_site;

              }
            }

          }
        }
      } else if (filter_words_source.length > 0 && ['source', 'sourceApp'].includes(position)) {
        for (j = 0; j < filter_words_source.length; j++) {
          for (i = 0; i < li.length; i++) {

            a = li[i].getElementsByTagName("div")[0];
            let id_site = li[i].querySelector('div[data-key]')?.getAttribute('data-key')
            if ((j === 0) || (j > 0 && li[i].style.display !== "none")) {
              txtValue = a.textContent || a.innerText;
              if (txtValue.toUpperCase().indexOf(filter_words_source[j]) > -1) {
                li[i].style.display = "";
                li[i].dataset.filtered = true;
                li[i].dataset.key = id_site;
              } else {
                li[i].style.display = "none";
                li[i].dataset.filtered = false;
                li[i].dataset.key = id_site;
              }
            }

          }
        }

      } else {
        for (i = 0; i < li.length; i++) {
          a = li[i].getElementsByTagName("div")[0];
          txtValue = a.textContent || a.innerText;
          let id_site = li[i].querySelector('div[data-key]')?.getAttribute('data-key')
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            li[i].style.display = "";
            li[i].dataset.filtered = true;
            li[i].dataset.key = id_site;
          } else {
            li[i].style.display = "none";
            li[i].dataset.filtered = false;
            li[i].dataset.key = id_site;
          }
        }
      }
    },


    importSections() {
      this.importSectionsDialog = true
    },
    importSection() {
      this.store.dispatch(Actions.IMPORT_FILE, {
        file: this.fileImportSections,
        model: 'importExigence',
        params: {arg1: this.idDocument,},
        document_id: this.idDocument,
      }).then(() => {
        this.toast.add({
          severity: 'success',
          detail: this.t('importFileWasSuccessfullySent'),
          life: 8000
        });
        this.getStatusImport()
        this.importSectionsDialog = false
      })
    },
    hideDialogImportSections() {
      this.importSectionsDialog = false

    },
    onUploadSections() {
      this.fileImportSections = this.$refs.file.files[0]
    },

    getStatusImport() {
      return this.store.dispatch(Actions.GET_STATUS_IMPORT, {
        'document_id': this.idDocument,
        'customHeader': true
      }).then(() => {
      })
    },

    onUploadSummaryFile() {
      this.summaryFile = this.$refs.fileSummary.files[0]
    },
    onUploadIllustrationFile() {
      this.illustrationFile = this.$refs.fileIllustration.files[0]
    },
    attachFile(idMedia, nameFile) {
      this.store.dispatch(Actions.ATTACH_FILES, {
        idMedia: idMedia,
        'customHeader': true
      }).then((data, filename) => {
        const url = window.URL.createObjectURL(data, filename);
        saveAs(url, nameFile)
      })
    },

    deleteFile(id, type) {
      this.confirm.require({
        message: this.t('AreYouSureYouWantToDelete'),
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.store.dispatch(Actions.DELETE_FILE_DOCUMENT, {
            type_file: type,
            id_doc: this.idDocument,
            idMedia: id
          }).then(() => {
            this.toast.add({
              severity: 'success',
              detail: this.t('fileHasSuccessfullyDeleted'),
              life: 3000
            });
            if (type == 'summary') {
              this.summary_file = ''
              this.summaryFile = ''
            } else {
              this.illustrations_file = ''
              this.illustrationFile = ''
            }
            // this.getDocument()
          })
        },
        reject: () => {
        },
        onHide: () => {
        }
      });
    },
    differenceDate(lastUpdateDate, liveStatusColor) {
      if (lastUpdateDate && liveStatusColor !== 'black') {
        lastUpdateDate = new Date(moment(lastUpdateDate).format('yy-MM-DD')).getTime()
        let currentDate = new Date(moment().format('yy-MM-DD')).getTime()
        const msInDay = 24 * 60 * 60 * 1000;
        let nbrDays = Math.round(
            Math.abs(currentDate - lastUpdateDate) / msInDay
        );
        if (nbrDays < 90)
          return true
      }
      return false;

    },
    exportDocumentSites() {
      //test
    },
    listDocuments() {
      this.DocumentsitesDialog = true
      this.loadingCopySites = true
      // this.documentsLoad = []
      if (this.documentsLoad?.length == 0) {
        this.store.dispatch(Actions.AFFECTATIONS_LIST, {document_id: this.idDocumentSelected}).then(result => {
          this.loadingCopySites = false
          this.documentsLoad = result.map(object => ({
            ...object,
            site: {
              id: object.site_id,
              name: object.name_site,
              is_applicable: object.is_applicable,
              typeLabel: object.applicablite
            },
            applicable: object.is_applicable ? 'Avec exigences' : 'Pour information',
            site_path: (object.site_path && typeof object.site_path === 'string') ? JSON.parse(object.site_path).full_path : object?.site_path?.full_path
          }))
          this.totalItemsDocumentSites = this.documentsLoad?.length
          // this.getSiteDocument({affectations: result, listValueSite: this.listValuesSite[0]})
        })
      } else {
        this.loadingCopySites = false
      }

    },
    getSelectedDocument(event) {
      this.idDocumentSelected = parseInt(event?.query)
      this.titleModalDocumentSites = ''
      this.documents = []
      this.documentsLoad = []
      this.selectedSites = []
      if (!isNaN(this.idDocumentSelected)) {
        this.store.dispatch(Actions.GET_DOCUMENTS, {'id': this.idDocumentSelected, limit: 30}).then((data) => {
          this.titleModalDocumentSites = data[0].title;
          this.documents.push(data[0].title)
        })
      }
    },
    hideDialogDocumentSites() {
      this.DocumentsitesDialog = false
    },
    validStatusSites() {
      this.selectedSites.forEach(element => {
        let elementSite = this.documentsLoad.filter(item => item.id == element.id)
        elementSite[0].is_applicable = this.valueStatusAffectDoc.is_applicable
        elementSite[0].applicable = this.valueStatusAffectDoc.label
        element.is_applicable = this.valueStatusAffectDoc.is_applicable
        element.applicable = this.valueStatusAffectDoc.label
      })
    },
    saveSitesDoc() {
      this.listValuesSite[0] = this.listValuesSite[0].filter(item1 => !this.selectedSites.some(item2 => item2.site_id === item1.id));
      this.DocumentsitesDialog = false
      this.getSiteDocument({affectations: this.selectedSites, listValueSite: this.listValuesSite[0]})
    },
    disableDoubleClick(event) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    },
    addDoubleClickListener() {
      setTimeout(() => {
        let sourceList = document.getElementsByClassName("p-picklist-list p-picklist-source")[0];
        let targetList = document.getElementsByClassName("p-picklist-list p-picklist-target")[0];

        if (sourceList) {
          sourceList.removeEventListener('dblclick', this.disableDoubleClick, true);
          sourceList.addEventListener('dblclick', this.disableDoubleClick, true);
        }
        if (targetList) {
          targetList.removeEventListener('dblclick', this.disableDoubleClick, true);
          targetList.addEventListener('dblclick', this.disableDoubleClick, true);
        }

        let sourceItems = sourceList ? sourceList.getElementsByClassName("p-picklist-item") : [];
        for (let item of sourceItems) {
          item.removeEventListener('dblclick', this.disableDoubleClick, true);
          item.addEventListener('dblclick', this.disableDoubleClick, true);
        }

        let targetItems = targetList ? targetList.getElementsByClassName("p-picklist-item") : [];
        for (let item of targetItems) {
          item.removeEventListener('dblclick', this.disableDoubleClick, true);
          item.addEventListener('dblclick', this.disableDoubleClick, true);
        }
      }, 100);
    },
    removeDoubleClickListener(){
      let sourceList = document.getElementsByClassName("p-picklist-list p-picklist-source")[0];
      let targetList = document.getElementsByClassName("p-picklist-list p-picklist-target")[0];

      if (sourceList) {
        sourceList.removeEventListener('dblclick ', this.disableDoubleClick);
      }
      if (targetList) {
        targetList.removeEventListener('dblclick ', this.disableDoubleClick);
      }

    },
    replaceAllColorsWithWhite(html) {
      if (!html) return '';
      return html.replace(/color: rgb\(\d{1,3}, \d{1,3}, \d{1,3}\);/g, 'color: white;');
    },
  }
}
</script>

<style lang="scss" scoped>
.list_site_copy {
  &.table-filter-texte {
    tr {
      td {
        .tooltip {
          p {
            max-width: 640px;
          }
        }
      }
    }
  }
}

.btn_download {
  align-items: center;
  justify-content: center;
}
</style>